import React, { useState } from "react";
import { Typography } from "@mui/material";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import {
  getSingleOrderResponseJson,
  getSingleOrderResponseJsonV2,
  orderStructureV2TableHead,
  orderStructureV2TableRow,
} from "../../constants";
import CodeContainer from "../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import JsonContainer from "../../components/JsonContainer";
import { codeFont, pageStyles, pageContent, body } from "../../styleConstants";
import VersionSwitch from "../../components/VersionSwitch";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import PageTitle from "../../components/PageComponents/PageTitle";
import BasicTable from "../../components/Table";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={6} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Get details about a single order" />
          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />

          <Typography variant="body1" style={body}>
            You can get the status and results of your order, with the request
            below, by specifying the order <code style={codeFont}>id</code>{" "}
            field.
          </Typography>
          {index === 0 ? (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v1/getorder/:id" />
          ) : (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/getorder/:id" />
          )}
          <ParagraphTitle text="Example Request" />
          {index === 0 ? (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v1/getorder/123456789" />
          ) : (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/getorder/123456789" />
          )}
          <ParagraphTitle text="Example Response" />
          {index === 0 ? (
            <JsonContainer text={getSingleOrderResponseJson} />
          ) : (
            <JsonContainer text={getSingleOrderResponseJsonV2} />
          )}
          <ParagraphTitle text="Response structure" />

          <Paragraph text="The response is structured to make it easier for you to understand the basic details of the order and what to expect next. Below is what you will see depending on the status of the order." />
          <BasicTable
            head={orderStructureV2TableHead}
            rows={orderStructureV2TableRow}
          />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
